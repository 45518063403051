<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Export des statuts (FlagTSMS)</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                    initialSortBy: { field: 'createdAt', type: 'desc' },
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'createdAt'">
                        {{ setCustomDateTime(props.row.createdAt) }}
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import moment from 'moment-timezone'
import store from '@/store'

export default {
    props: {},
    name: 'ILogFlagTSms',
    data: () => ({
        isLoading: false,
        serverParams: {
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
    }),
    computed: {
        columns: function () {
            let column = [
                {
                    label: 'OS n°',
                    field: 'missionOrder.id',
                    sortable: true,
                },
                {
                    label: 'Réference G2',
                    field: 'missionOrder.refDemandeG2',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Code erreur',
                    field: 'errorCode',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: "Message d'erreur",
                    field: 'errorMessage',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Nombre de tentative',
                    field: 'numberOfAttemptUpdateFlag',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Entrée',
                    field: 'input',
                    filterOptions: {
                        enabled: false,
                    },
                    sortable: true,
                },
                {
                    label: 'Sortie',
                    field: 'output',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Statut',
                    field: 'status',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Date de création',
                    field: 'createdAt',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
            ]

            return column
        },
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        updateValues(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems() {
            this.isLoading = true
            backendApi
                .getLogFlagTSms(store.state.login.user.token, this.serverParams)
                .then((data) => {
                    this.remoteData.totalRecords = data.totalRecords
                    this.remoteData.rows = data.rows
                    this.updateParams({
                        page: data.currentPage,
                        perPage: data.maxResult,
                    })
                })
                .catch((e) => {
                    this.isLoading = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        setCustomDateTime(date) {
            return moment(date).format('DD/MM/YYYY HH:mm:ss')
        },
    },
    mounted() {
        this.loadItems()
    },
}
</script>
<style></style>
